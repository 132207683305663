import React from "react"
import Layout from "../components/layout"
import NewsStyledBackgroundSection from "../components/news-background-image"
import Seo from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"

class News extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    }
  }

  handleClick1 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show1) ? false : true;
    this.setState({ show1: displayPopup });
  }
  handleClick2 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show2) ? false : true;
    this.setState({ show2: displayPopup });
  }
  handleClick3 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show3) ? false : true;
    this.setState({ show3: displayPopup });
  }
  handleClick4 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show4) ? false : true;
    this.setState({ show4: displayPopup });
  }
  handleClick5 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show5) ? false : true;
    this.setState({ show5: displayPopup });
  }
  handleClick6 = () => {
    this.setState({
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
    });
    let displayPopup = false;
    displayPopup = (this.state.show6) ? false : true;
    this.setState({ show6: displayPopup });
  }

  render = () => {
    return (
      <Layout>
        <Seo title="Home" />
        <NewsStyledBackgroundSection />
        <div className="news-grid">

          <div className={(this.state.show1) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.ida.childImageSharp.fluid}
              alt="Ida B. Wells"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show1) ? "less-button" : "more-button"} onClick={() => this.handleClick1()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title">Ida B. Wells</p>
            <div className={(this.state.show1) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Ida B. Wells (1862-1931)</h3>
              <p>Ida B. Wells was an investigative reporter, Black feminist activist and a community organizer who reported on and combatted lynching in the American South. Born into slavery during the Civil War, Wells became an editor and part owner of The Memphis Free Speech. In 1892, she refuted claims that a close friend and his workers had been lynched because they were plotting against whites. Other local papers maligned Wells, and a white mob destroyed the newspaper’s office. After the attack, Wells moved to the North and continued her work. She used investigative reporting and data journalist skills to document the realities of lynching in the South, often counter to what was reported in mainstream press. She went on speaking tours in Britain to find international support against American racism, and later campaigned for women’s suffrage and helped found the National Association of Colored Women in 1896 and the National Association for the Advancement of Colored People in 1909.</p>
            </div>
          </div>

          <div className={(this.state.show2) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.stan.childImageSharp.fluid}
              alt="Stan Strachan"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show2) ? "less-button" : "more-button"} onClick={() => this.handleClick2()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title">Stan Strachan</p>
            <div className={(this.state.show2) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Stan Strachan (1939-1997)</h3>
              <p>Stan Strachan was a watchdog journalist who chronicled the financial savings and loan crisis of the 1980s. Strachan began his career as a copy boy at the New York Journal-American in 1947. He then joined the American Banker, a daily trade newspaper that covers the financial services industry, as a sports and politics writer. Strachan became assistant managing editor at American Banker before he left the publication around 1971 to work as an independent journalist. In 1976, he co-founded the National Thrift News, a trade publication aimed at reporting on the savings and loan industry. Strachan’s sharp beat reporting and in-depth investigative reporting led to the National Thrift News breaking news about banker Charles Keating’s savings and loan misdoings, two years before The New York Times and The Washington Post. Strachan broke the news that Keating used depositors’ savings for personal real estate ventures and stocks. After the Federal Home Loan Bank Board imposed a limit on savings and the FBI homed in on his company, Keating contributed $1.3 million to five U.S. senators’ campaigns in exchange for their support. Strachan was the first to report that Keating and the senators had met. In 1988, Strachan and the National Thrift News staff won the George K. Polk Award.</p>
            </div>
          </div>

          <div className={(this.state.show3) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.juan.childImageSharp.fluid}
              alt="Juan Haines"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show3) ? "less-button" : "more-button"} onClick={() => this.handleClick3()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title">Juan Haines</p>
            <div className={(this.state.show3) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Juan Haines</h3>
              <p>Juan Haines is the managing editor of one of the country’s few prison newspapers, the San Quentin News, a publication produced for and by prison inmates at San Quentin State Prison in California. In 1966, Haines was sentenced to 55 years to life after being convicted of multiple bank robberies. He joined the San Quentin News after a friend urged him to join the staff. Haines helps a staff of 12 to 15 incarcerated men, a team of volunteers and professional advisers create a 20-page monthly paper that is distributed to nearly 4,000 men at San Quentin and all 35 California prisons. Haines’ focus at the San Quentin Newspaper is to portray inmates as nuanced people. He has written about the physical conditions of incarceration, intergenerational incarceration and the disenfranchisement of people convicted of felonies, among other topics. Each article featured in the San Quentin News must be reviewed by the California Department of Corrections and Rehabilitation before publication, challenging Haines’ editorial control. In 2017, Haines won a Society of Professional Journalists Silver Heart award for encouraging “first time journalists to use their time in prison to report on the courts, the prisons and the conditions of incarceration,” the SPJ wrote in announcing Haines’ win. “With Haines’ encouragement and mentorship, their work has contributed to deeper understanding of the prison system by those both inside and outside the prison walls.”</p>
            </div>
          </div>

          <div className={(this.state.show4) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.baranFreeman.childImageSharp.fluid}
              alt="Madeleine Baran and Samara Freemark"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show4) ? "less-button" : "more-button"} onClick={() => this.handleClick4()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title" style={{fontSize: `1rem`}}>Madeleine Baran and Samara Freemark</p>
            <div className={(this.state.show4) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Madeleine Baran and Samara Freemark</h3>
              <p>Madeleine Baran and Samara Freemark hold truth to power.  Baran is an investigative reporter for American Public Media Reports while Freemark is a reporter and producer for the public radio network. Baran and Freemark collaborate on “In the Dark,”  an investigative journalism podcast that balances ethical rigor, journalistic diligence and suspenseful stories to hold powerful people and institutions accountable. Baran hosts, narrates and reports for the podcast and Freemark produces the episodes. Baran joined APM in 2015, three years after Freeman.
              “In the Dark” premiered in 2016. Its first season, which won a Peabody Award, explores the cold case of Jacob Wetterling, an 11-year-old boy who was abducted in 1989. The Wetterling case led to the creation of national sex-offender registries. The second season unpacks “the case of Curtis Flowers, a Black man from a small town in Mississippi who has spent the past twenty-one years fighting for his life, and a white prosecutor who spent that same time trying just as hard to execute him,” listeners are told in the first episode. In June 2019, a year after Flowers’ story was told on “In the Dark,” the U.S. Supreme Court reversed his conviction.
              </p>
            </div>
          </div>

          <div className={(this.state.show5) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.monica.childImageSharp.fluid}
              alt="Monica Roberts"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show5) ? "less-button" : "more-button"} onClick={() => this.handleClick5()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title">Monica Roberts</p>
            <div className={(this.state.show5) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Monica Roberts (1962-2020)</h3>
              <p>Monica Roberts was a writer, blogger, and trans human rights, advocate. As the founding editor of TransGriot, she helped build acceptance and understanding of trans people of all communities. After Roberts transitioned in 1994, she found that there were few people of color in the support groups she attended. In 2001, Roberts started working as a monthly column writer for a paper in Louisville. It was there that she realized blogging as a medium had the power to reach her community in real time. When Roberts founded the TransGriot in 2006, she was acutely aware of the racism in the LGBTQ community and began using the blog to champion critiques of anti-trans sentiment and violence, while uplifting trans voices. “There weren’t really any blogs talking about the issues from a Black trans perspective,” she said in an interview with Queerty in 2019, “I went with the, ‘You want it done right? You gotta do it yourself.’” Roberts regularly utilized the platform to address power disparities and to call out larger media entities for their poor coverage of the murders of trans people. Her work as both a journalist and advocate pioneered conversations and legislative change. In that same Queerty interview, Roberts noted that for perhaps the first in modern history, trans people have the opportunity for intergenerational conversations that can help allow for new approaches to solving institutional barriers that exist for the community. Roberts died in her hometown of Houston on Oct. 5, 2020.</p>
            </div>
          </div>

          <div className={(this.state.show6) ? 'float-left image-wrapper' : 'image-wrapper'}>
            <Img
              fluid={this.props.data.jeffrey.childImageSharp.fluid}
              alt="Jeffrey Schmalz"
              style={{ width: `100%`, }}
            />
            <button className={(this.state.show6) ? "less-button" : "more-button"} onClick={() => this.handleClick6()}><span className="sr-only">{(this.state.show2) ? 'Less' : 'More'}</span></button>
            <p className="flipper-title">Jeffrey Schmalz</p>
            <div className={(this.state.show6) ? 'show expanded-text' : 'hide expanded-text'}>
              <h3>Jeffrey Schmalz (1953-1993)</h3>
              <p>Jeffrey Schmalz was an American journalist who spent his final days reporting on AIDS. He was a fast-rising editor at The New York Times, where he had kept his sexuality hidden from homophobic management. This became impossible after Schmalz collapsed at his desk in December 1990. He was later diagnosed with AIDS and an AIDS-related brain infection. It was rare for someone with his diagnosis to survive more than a few months. But Schmalz returned to the newsroom a year later and convinced his editors to let him cover the AIDS epidemic. He spent the next few years writing deeply human stories that had a major impact on the disease's public perception. He is said to have brought faces to the AIDS epidemic with his sharp yet personal interviews with high-profile AIDS patients like Magic Johnson, Elizabeth Glaser and Mary Fisher. Schmalz died on Nov. 6, 1993, a month before his 40th birthday; his last story “Whatever Happened to AIDS?” ran on Nov. 28.</p>
            </div>
          </div>
        </div>
        <div className="briscoe-credit">
          <div>
            <p className="small-copy">Dan Rather and the Dalai Lama for the</p>
            <p className="small-copy">2007 report "One Man vs. China.” </p>
            <p className="small-copy">Rather (Dan) Papers, Briscoe Center for American History</p>
          </div>
        </div>
      </Layout>
    )
  }

}

export default News

export const query = graphql`
  query {
    ida: file(relativePath: { eq: "idawells-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    stan: file(relativePath: { eq: "stan-strachan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    juan: file(relativePath: { eq: "juan_moreno-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    baranFreeman: file(relativePath: { eq: "UTDR-BaranFreeman-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    monica: file(relativePath: { eq: "monica_roberts.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jeffrey: file(relativePath: { eq: "jeffrey_schmalz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
