import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "dan-and-dalai-lama.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <div>
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          // backgroundColor={`#005f86`}
          id="hero-section"
          style={{ marginTop: `100px`, }}
        >
        </BackgroundImage>
          <div className="hero-textbox pad">
            <div id="award-hero-textbox">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12" style={{
                    maxWidth: `880px`,
                    marginLeft: `auto`,
                    marginRight: `auto`,
                  }}>
                    <h1 className="mb-5 semibold serif">News, Guts and History</h1>
                    <p className="copy mb-5 text-left">This gallery of journalists who have displayed news and guts throughout American history is not
                    designed to call upon a golden age of journalism. Instead it highlights journalists who have
                    exemplified the best of what the values of truth seeking can offer, and the work or sacrifices it
                    can take to reach them. While some journalists are well known for their impactful stories,
                    like The Washington Post’s Bob Woodward and Carl Bernstein of Watergate fame, others
                    have done the meaningful work while receiving far less attention. Here are but six examples of pushing the status quo, pushing back on powerful systems, and serving as the voice of the voiceless.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const NewsStyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default NewsStyledBackgroundSection
